import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../share/utility';

const initialState = {
    loadingGastos: false,
    successGastos: false,
    errorGastos: false,
    showGastosModal: false,
    accion: false,
    response: false
}

const gastos_start = (state, action) => {
    return updateObject(state, {
        loadingGastos: true,
        successGastos: false,
        errorGastos: false,
        showGastosModal: false,
        response: false
    })
}

const gastos_success = (state, action) => {
    return updateObject(state, {
        loadingGastos: false,
        successGastos: true,
        errorGastos: false,
        showGastosModal: false,
        response: action.response,
    })
}

const gastos_fail = (state, action) => {
    return updateObject(state, {
        loadingGastos: false,
        successGastos: false,
        errorGastos: true,
        showGastosModal: false,
        response: action.response        
    })
}

const reducer = (state=initialState, action) => {
    switch(action.type){
        case actionTypes.GASTOS_START:
            return gastos_start(state, action);
        case actionTypes.GASTOS_SUCCESS:
            return gastos_success(state, action);
        case actionTypes.GASTOS_FAIL:
            return gastos_fail(state, action);
        default:
            return state;
    }
}

export default reducer;
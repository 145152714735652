import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../share/utility';

const initialState = {
    loadingCitas: false,
    successCitas: false,
    errorCitas: false,
    showCitasModal: false,
    responseCitas: false,
    rspCitasWeek: false,
}

const citas_start = (state, action) => {
    return updateObject(state, {
        loadingCitas: true,
        successCitas: false,
        errorCitas: false,
        responseCitas: false,
        rspCitasWeek: false,
    })
}

const citas_success = (state, action) => {
    return updateObject(state, {
        loadingCitas: false,
        successCitas: true,
        errorCitas: false,
        showCitasModal: false,
        responseCitas: action.response,
        rspCitasWeek: action.rspCitasWeek
    })
}

const citas_fail = (state, action) => {
    return updateObject(state, {
        loadingCitas: false,
        successCitas: false,
        errorCitas: true,
        showCitasModal: false,
        responseCitas: false,
        rspCitasWeek: false,
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CITAS_START:
            return citas_start(state, action);
        case actionTypes.CITAS_SUCCESS:
            return citas_success(state, action);
        case actionTypes.CITAS_FAIL:
            return citas_fail(state, action);
        default:
            return state;
    }
}

export default reducer;
import * as actionTypes from './actionTypes';
import axios from 'axios';

export const addStart = () => {
    return{
        type: actionTypes.ADD_SERVICE_START
    }
};

export const addSuccess = (response) => {
    return{
        type: actionTypes.ADD_SERVICE_SUCCESS,
        response: response
    }
}

export const addFail = (response) => {    
    return{
        type: actionTypes.ADD_SERVICE_FAIL,
        response: response
    }
}

export const addServiceReset = () => {
    return{
        type: actionTypes.ADD_SERVICE_RESET
    }
}

export const addServiceExec = (nombre, edad, servicio, costo, descuento, pago, metodoPago, barbero, comision, monto, propina, bebida ,datetimeConvert, producto) => {
    return dispatch => {
        dispatch(addStart());
        const addData = {
            nombre: nombre,
            edad: edad,
            servicio: servicio,
            costo: costo,
            descuento: descuento,
            pago: pago,
            metodoPago: metodoPago,
            barbero: barbero, 
            comision: comision, 
            monto: monto, 
            propina: propina,
            bebida: bebida,             
            datetime: datetimeConvert,
            producto: producto,
            tokenId: localStorage.getItem('tokenID')
        }
        axios.post(process.env.REACT_APP_SERVER_IP + '/addService', addData, {
            headers: { 'Authorization': localStorage.getItem('tokenID') }
        })
            .then(response => {                                 
                dispatch(addSuccess(response));                
            })
            .catch(err => {            
                dispatch(addFail(err))
            })
    }
}
export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};


export const checkValidity = (value, rules) => {
    let isValid = true;
    if(!rules){
        return true;
    }

    if(rules.require){            
        isValid = value.trim() !== '' && isValid;
    }
    if(rules.minLength){
        isValid = value.length >= rules.minLength && isValid
    }
    if(rules.maxLength){
        isValid = value.length <= rules.maxLength && isValid
    }
    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid
    }
    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }
    if (rules.isFloat){
        const pattern = /[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)/;
        isValid = pattern.test(value) && isValid
    }
    if(rules.isSelected){
        isValid = value !== 'Nada' && isValid
    }            
    return isValid;
}

export const formatedDate = (date) => {
        const year = date.getFullYear()
        var month = '0'
        var monthNumber = date.getMonth() + 1;
        if (monthNumber < 10){            
            month = '0' + (monthNumber)
        } else {
            month = monthNumber;
        }
        var day = '0'
        if (date.getDate() < 10){
            day = '0' + date.getDate()
        } else {
            day = date.getDate()
        }        
        const DateToDB = year + '-' + month + '-' + day + ' ';
        return DateToDB;   
}

export const dateWithHours = (date) => {
        const year = date.getFullYear()
        var month = '0'
        var monthNumber = date.getMonth() + 1;
        if (date.getMonth() < 10){
            month = '0' + (monthNumber);
        } else {
            month = monthNumber;
        }
        var day = '0'
        if (date.getDate() < 10){
            day = '0' + date.getDate()
        } else {
            day = date.getDate()
        }
        var hour = '0'               
        if (date.getHours() < 10){
            hour = '0' + date.getHours()
        } else {
            hour = date.getHours()
        } 
        var minute = '0'               
        if (date.getMinutes() < 10){
            minute = '0' + date.getMinutes()
        } else {
            minute = date.getMinutes()
        } 
        var second = '0'               
        if (date.getSeconds() < 10){
            second = '0' + date.getSeconds()
        } else {
            second = date.getSeconds()
        }                             
        const DateToDB = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second; 
        return DateToDB;   
}

export const lastDay = (date) => {
        const year = date.getFullYear()
        var month = '0'
        var monthNumber = date.getMonth() + 1;
        if (monthNumber < 10){         
            month = '0' + (monthNumber)
        } else {
            month = monthNumber;
        }
        var day = '0'
        if (date.getDate() < 10){
            day = '0' + (date.getDate() + 1)
        } else {
            day = (date.getDate() + 1)
        }               
        const DateToDB = year + '-' + month + '-' + day + ' '; 
        return DateToDB;   
}

export const tittleDate = (date) => {
        const year = date.getFullYear()
        const nDate = new Date(date);
        var day = date.getDate()
        let longMonth = nDate.toLocaleString('es-ES',{month: 'long'});
        if (date.getDate() < 10){
            day = '0' + (date.getDate())
        }  
        const toSendDate = day + '-' + longMonth + '-' + year      
        return toSendDate;   
}

export const currencyFormat = (num) => {
    const numbertosend = '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return numbertosend;
}

export const percentageFormat = (num) => {
    const numbertosend = (num * 100) + '%';
    return numbertosend;
}

export const sortedDate = (personal, citas) => {
    let personalNames = Object.values(personal);
    let arrayCitas = [];   
    for (let i = 0; i < Object.keys(personal).length; i++) {
        var personalName = personalNames[i].value;
        for (let j = 0; j < Object.keys(citas).length; j++) {
            var citasArray = Object.keys(citas[j]);
            var citasValues = Object.values(citas[j]);
            for (let k = 0; k < citasArray.length; k++) {
                if (citasArray[k] === 'personal' && citasValues[k] === personalName) {
                    var DateToConvert = new Date(citasValues[3]);
                    arrayCitas.push({
                        personal: personalName,
                        cliente: citasValues[1],
                        fecha: citasValues[3],
                        dia: DateToConvert.getDate(),
                        mes: DateToConvert.getMonth() + 1,
                        year: DateToConvert.getFullYear(),
                        hora: DateToConvert.getHours(),
                        minuto: DateToConvert.getMinutes(),
                        diaNumber: DateToConvert.getDay(),
                        servicio: citasValues[4],
                        contacto: citasValues[5],
                        duracion: citasValues[10],
                        idCita: citasValues[0],
                        noCliente: citasValues[2],
                    });
                }
            }
        }
    }   
    return arrayCitas; 
}

export const getWeekDays = (date) => {
    var initDateArray = [];
    var finishDateArray = [];
    var newDate = new Date(date);
    var beforeDate = new Date(date);
    var finish = new Date(date);
    let initDate = new Date(beforeDate.setDate(newDate.getDate() - newDate.getDay()));
    let finishDate = new Date(finish.setDate(newDate.getDate() + (6 - newDate.getDay())));

    let initDateDB = formatedDate(initDate);
    let finishDateDB = formatedDate(finishDate) + '23:59:59.999';
    initDateArray.push({
        date: initDate, 
        diaNum: initDate.getDay(),
        month: initDate.getMonth(),
        dia: initDate.getDate(),
        year: initDate.getFullYear(),
        initDateDB: initDateDB});

    finishDateArray.push({
        date: finishDate,
        diaNum: finishDate.getDay(),
        month: finishDate.getMonth(),
        dia: finishDate.getDate(),
        year: finishDate.getFullYear(),
        finishDateDB: finishDateDB});                         

    return { initDateArray, finishDateArray }
}

export const arrTimes = (eleccion) => {
    let hoursArray = [];
    let hourString = [];
    let dayNumbers = [];
    let dayString = [];        
    if(eleccion){
        hoursArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
        hourString = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00',
            '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];
        dayNumbers = [0, 1, 2, 3, 4, 5, 6];
        dayString = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'];        
    }
    return {hoursArray, hourString, dayNumbers, dayString};
}


export const getDateElements = (date) => {
    var initDateArray = [];
    const mes = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    let mesString = ''
    for(let i = 0; i < mes.length; i++){
        if ((date.getMonth()) === i){
            mesString = mes[i];
        }
    }
    initDateArray.push({
        date: date,
        diaNum: date.getDay(),
        month: date.getMonth(),
        dia: date.getDate(),
        year: date.getFullYear(),
        mesString: mesString
    });
    return { initDateArray }
}

export const createArrayDays = (overallWithGastos) => {
    var numberDate = []
    var daysInCurrentMonth=new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
    var numberLine = [];
    for(let i = 0; i < daysInCurrentMonth; i++){
        numberDate.push(i+1);
        numberLine.push(overallWithGastos)
    }
    return {numberDate, numberLine, daysInCurrentMonth};
}

export const compoundArray = (data, gastosData) => {
    var numberDate = []    
    var numberLine = [];
    var daysInCurrentMonth=new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();    
    const databyDay = data/daysInCurrentMonth;
    var daysToProfit = 0;
    var continueLoop = true;
    
    for(let i = 0; i < daysInCurrentMonth; i++){
        numberDate.push(i+1);
        if(i > 0){
            numberLine.push(numberLine[i-1] + databyDay);
        }else{
            numberLine.push(databyDay);
        }
    }

    for(let j = 0; j < daysInCurrentMonth; j++){
        if(numberLine[j] > gastosData && continueLoop){
            daysToProfit = (j+1)
            continueLoop = false
        }
    }
    return {numberDate, numberLine, daysInCurrentMonth, daysToProfit};
}

export const createArraysFlexPlot = (data, XAxis, YAxis) => {
    var XAxisArray = [];
    var YAxisArray = [];
    for(let i = 0; i<data.length; i++){
        for (const property in data[i]) {
            if(property === XAxis){
                XAxisArray.push(`${data[i][property]}`)
            }
            if(property === YAxis){
                YAxisArray.push(`${data[i][property]}`)
            }
          }
    }    
    return {XAxisArray, YAxisArray};
}


import * as actionTypes from './actionTypes';
import axios from '../../axios-main';

export const gastos_start = () => {
    return{
        type: actionTypes.GASTOS_START
    }
}

export const gastos_success = (response) => {
    return{
        type: actionTypes.GASTOS_SUCCESS,
        response: response
    }
}

export const gastos_fail = (response) => {
    return{
        type: actionTypes.GASTOS_FAIL,
        response: response
    }
}

export const addGastosExec = (descripcion, tipo) => {
    return dispatch => {
        dispatch(gastos_start());
        const addData = {
            descripcion: descripcion,
            tipo: tipo,
            tokenId: localStorage.getItem('tokenID')
        }
        axios.post(process.env.REACT_APP_SERVER_IP + '/addGastos', addData, {
            headers: { 'Authorization': localStorage.getItem('tokenID') }
        })
            .then(response => {
                dispatch(gastos_success(response));
            })
            .catch(err => {
                dispatch(gastos_fail(err));
            })
    }
}

export const getGastosExec = () => {
    return dispatch => {
        dispatch(gastos_start());
        const addData = {            
            tokenId: localStorage.getItem('tokenID'),            
        }
        axios.post(process.env.REACT_APP_SERVER_IP + '/getListGastosAll', addData, {
            headers: { 'Authorization': localStorage.getItem('tokenID') }
        })
            .then(response => {                
                dispatch(gastos_success(response));                
            })
            .catch(err => {
                dispatch(gastos_fail(err));
            })
    }
}

export const updateGastosExec = (descripcion, active, id) => {
    return dispatch => {
        dispatch(gastos_start());
        const addData = {
            descripcion: descripcion,
            active: active,
            id: id,
            tokenId: localStorage.getItem('tokenID')
        }
        axios.post(process.env.REACT_APP_SERVER_IP + '/updateGastos', addData, {
            headers: { 'Authorization': localStorage.getItem('tokenID') }
        })
            .then(response => {
                dispatch(gastos_success(response));
            })
            .catch(err => {
                dispatch(gastos_fail(err));
            })
    }
}
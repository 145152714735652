import * as actionTypes from './actionTypes';
import axios from 'axios';
import { dateWithHours } from '../../share/utility';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
};

export const authSuccess = (token, userId, email, whois) => {    
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        userId: userId,
        email: email,
        whois: whois
    }
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');
    localStorage.removeItem('email');   
    localStorage.removeItem('connectID'); 
    localStorage.removeItem('username');
    localStorage.removeItem('whois');
    localStorage.removeItem('tokenID')
    return {
        type: actionTypes.AUTH_LOGOUT
    }
};

export const checkAuthTimeOut = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 10000)
    }
}

export const auth = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            email: email,
            password: password,
            returnSecureToken: true
        }
        let url = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=' + process.env.REACT_APP_FIREBASE_KEY;
        let urlLogin = process.env.REACT_APP_SERVER_IP + '/login';
        let foundError = false;        
        let whois = 'recepcion';
        let errorMessage = 'No_Error';
        let idToken = 'No idToken';
        let localId = 'No localID';
        let expiresIn = 'No Expire';    
        axios.post(url, authData)
            .then(response => {                
                const expirationDate = new Date(new Date().getTime() + response.data.expiresIn *1000);                
                localStorage.setItem('token', response.data.idToken);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('userId', response.data.localId);
                localStorage.setItem('email', email);  
                const newDate = new Date();
                const dateToLogger = dateWithHours(newDate);                              
                const loginData = {
                    email: email,
                    password: password,
                    tokenDeviceId: response.data.idToken,
                    whenLogged: dateToLogger,
                    gettoken: 1        
                }
                idToken = response.data.idToken;
                localId = response.data.localId;
                expiresIn = response.data.expiresIn;                
                axios.post(urlLogin, loginData)
                    .then(response => {                        
                        if (response.data.rol === '9'){whois = 'barbmaster'}
                        if (response.data.rol === '2'){whois = 'barbadmin'} 
                        if (response.data.rol === '3') { whois = 'barbadmin' } 
                        localStorage.setItem('whois', whois);
                        localStorage.setItem('tokenID', response.data.tokenId);
                        localStorage.setItem('name', response.data.nombre); 
                        switch (response.data.message) {
                            case "error en el query":
                                errorMessage = "Error en la petición.";
                                foundError = true;
                                break;
                            case "Usuario no encontrado":
                                errorMessage = "Usuario no encontrado - revisa tu Email";
                                foundError = true;
                                break;
                            case "Success":                                                            
                                dispatch(authSuccess(idToken, localId, email, whois));
                                dispatch(checkAuthTimeOut(expiresIn));
                                foundError = false;                            
                                break;                        
                            default:
                                errorMessage = response.data.message;
                        }
                        if(foundError){
                            dispatch(authFail(errorMessage));
                        }
                            
                    })
                    .catch(err => {                
                            dispatch(authFail(err))
                    })                                              
            })
            .catch(err => {                
                switch (err.response.data.error.message) {
                    case "ERROR_INVALID_EMAIL":
                        errorMessage = "Your email address appears to be malformed.";
                        break;
                    case "INVALID_PASSWORD":
                        errorMessage = "Your password is wrong.";
                        break;
                    case "EMAIL_NOT_FOUND":
                        errorMessage = "User with this email doesn't exist.";
                        break;
                    case "ERROR_USER_DISABLED":
                        errorMessage = "User with this email has been disabled.";
                        break;
                    case "TOO_MANY_ATTEMPTS_TRY_LATER":
                        errorMessage = "Too many requests. Try again later.";
                        break;
                    case "ERROR_OPERATION_NOT_ALLOWED":
                        errorMessage = "Signing in with Email and Password is not enabled.";
                        break;
                    default:
                        errorMessage = err.response.data.error.message;
                }                
                    dispatch(authFail(errorMessage))
            })
    }
}

export const setAuthRedirectPath = (path) => {
    return{
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    }
}

export const authCheckState = () => {    
    return dispatch => {
        const token = localStorage.getItem('token');        
        if(!token){
            dispatch(logout());                      
        }else{
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            const userId = localStorage.getItem('userId');
            dispatch(authSuccess(token, userId));
            if(expirationDate <= new Date()){
                dispatch(logout());                
            }else{
                const userId = localStorage.getItem('userId');
                dispatch(authSuccess(token, userId));
                dispatch(checkAuthTimeOut((expirationDate.getTime() - new Date().getTime())/10000));                
            }
        }
    }   
}

export const checkDataBase = (email, password) => {
    return dispatch => {
        const authData = {
            email: email,
            password: password,
            tokenDeviceId: 'NODATAYET',
            gettoken: 0        
        }
        let url = 'http://35.91.87.239:3800/api/login/';        
        let errorMessage = 'No_Error';
        let foundError = false;
        axios.post(url, authData)
            .then(response => {                
                switch (response.data.message) {
                    case "Usuario no activo":
                        errorMessage = "Usuario no activo - contacta al administrador.";
                        foundError = true;
                        break;
                    case "Usuario no encontrado":
                        errorMessage = "Usuario no encontrado - revisa tu Email";
                        foundError = true
                        break;
                    case "No se enviaran datos":
                        dispatch(auth(email, password))
                        foundError = false
                        break;                        
                    default:
                        errorMessage = 'No Error';
                }
                if(foundError){
                    dispatch(authFail(errorMessage));
                }
                    
            })
            .catch(err => {                
                    dispatch(authFail(err))
            })
    }
}
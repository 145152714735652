import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../share/utility';

const initialState = {
    loadingClientes: false,
    successClientes: false,
    errorClientes: false,
    showClientesModal: false,
    responseClientes: false,
    responseCtrUpd: false,
}

const clientes_start = (state, action) => {
    return updateObject(state, {
        loadingClientes: true,
        successClientes: false,
        errorClientes: false,
        responseClientes: false,
        respCtrUpd: false,
    })
}

const clientes_success = (state, action) => {
    return updateObject(state, {
        loadingClientes: false,
        successClientes: true,
        errorClientes: false,
        showClientesModal: false,
        responseClientes: action.response,
        respCtrUpd: action.respupdCtr
    })
}

const clientes_fail = (state, action) => {
    return updateObject(state, {
        loadingClientes: false,
        successClientes: false,
        errorClientes: true,
        showClientesModal: false,
        responseClientes: false,
        respCtrUpd: false,
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CLIENTES_START:
            return clientes_start(state, action);
        case actionTypes.CLIENTES_SUCCESS:
            return clientes_success(state, action);
        case actionTypes.CLIENTES_FAIL:
            return clientes_fail(state, action);
        default:
            return state;
    }
}

export default reducer;
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import registerserviceWorker from './registerServiceWorker';
import authReducer from './store/reducers/auth';
import addServiceReducer from './store/reducers/addService';
import reportsReducer from './store/reducers/reports';
import loggerReducer from './store/reducers/toLogFile';
import extraReducer from './store/reducers/extras';
import barberReducer from './store/reducers/barberos';
import inventarioReducer from './store/reducers/inventario';
import inventLogReducer from './store/reducers/loggerInvent';
import chatbotReducer from './store/reducers/chatbot';
import chatSendReducer from './store/reducers/chatbotSend';
import controlReducer from './store/reducers/control';
import citasReducer from './store/reducers/citas';
import clientesReducer from './store/reducers/clientes';
import gastosReducer from './store/reducers/gastos';
import ReactPixel from 'react-facebook-pixel';

const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  addService: addServiceReducer,
  reports: reportsReducer,
  loggerReducer: loggerReducer,
  extraReducer: extraReducer,
  barberReducer: barberReducer,
  inventarioReducer: inventarioReducer,
  inventLogReducer: inventLogReducer,
  chatbotReducer: chatbotReducer,
  chatSendReducer: chatSendReducer,
  controlReducer: controlReducer,
  citasReducer: citasReducer,
  clientesReducer: clientesReducer,
  gastosReducer: gastosReducer
})

ReactPixel.init(process.env.PIXEL_FACEBOOK_ID);
ReactPixel.pageView();

const store = createStore(rootReducer, composeEnhancers(
  //applyMiddleware(thunk, gaMiddleware)
  applyMiddleware(thunk)
));

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
ReactDOM.render(app, document.getElementById('root'))

reportWebVitals();
registerserviceWorker();

import * as actionTypes from './actionTypes';
import axios from '../../axios-main';
import { reviewError } from '../../share/handlingErrors';

export const clientes_start = () => {
    return {
        type: actionTypes.CLIENTES_START
    }
}

export const clientes_success = (response) => {
    return {
        type: actionTypes.CLIENTES_SUCCESS,
        response: response,
    }
}

export const clientes_fail = (response) => {
    return {
        type: actionTypes.CLIENTES_FAIL,
        response: response
    }
}

export const getAllClientesExec = () => {
    return dispatch => {
        dispatch(clientes_start());
        const addData = {
            tokenId: localStorage.getItem('tokenID')
        }
        axios.post('/getAllClientes', addData, {
            headers: { 'Authorization': localStorage.getItem('tokenID') }
        })
            .then(response => {
                var elementso = updateStateElements(response.data.message);
                dispatch(clientes_success(elementso));
            })
            .catch(err => {
                const errorMsg = reviewError(err.response.status);
                dispatch(clientes_fail(errorMsg.errorMessage));
            })
    }
}

const updateStateElements = (clientes) => {
    const arrClientes = [{ value: 0, displayValue: 'Clientes' }];
    for (let i = 0; i < clientes.length; i++) {
        arrClientes.push({ value: i+1, displayValue: clientes[i].cliente, telefono: clientes[i].telefono, no_cliente: clientes[i].id })
    }
    return arrClientes;
}

export const getClientesExec = (cliente, no_cliente) => {
    return dispatch => {
        dispatch(clientes_start());
        const addData = {
            cliente: cliente,
            no_cliente: no_cliente,
            tokenId: localStorage.getItem('tokenID')
        }
        axios.post('/getCliente', addData, {
            headers: { 'Authorization': localStorage.getItem('tokenID') }
        })
            .then(response => {
                dispatch(clientes_success(response.data.message));
            })
            .catch(err => {
                const errorMsg = reviewError(err.response.status);
                dispatch(clientes_fail(errorMsg.errorMessage));
            })
    }
}

export const addClienteExec = (cliente, genero, telefono, fecha) => {
    return dispatch => {
        dispatch(clientes_start());
        const addData = {
            cliente: cliente,
            genero: genero,
            telefono: telefono,
            fecha: fecha,       
            tokenId: localStorage.getItem('tokenID')
        }
        axios.post('/addCliente', addData, {
            headers: { 'Authorization': localStorage.getItem('tokenID') }
        })
            .then(response => {
                dispatch(clientes_success(response.data.message));
            })
            .catch(err => {
                const errorMsg = reviewError(err.response.status);
                dispatch(clientes_fail(errorMsg.errorMessage));
            })
    }
}

export const updateClientesExec = (cliente, genero, telefono, fecha, id) => {
    return dispatch => {
        dispatch(clientes_start());
        const addData = {
            cliente: cliente,
            genero: genero,
            telefono: telefono,
            fecha: fecha,            
            id: id,
            tokenId: localStorage.getItem('tokenID')
        }
        axios.post('/updateCliente', addData, {
            headers: { 'Authorization': localStorage.getItem('tokenID') }
        })
            .then(response => {
                dispatch(clientes_success(response.data.message));
            })
            .catch(err => {
                const errorMsg = reviewError(err.response.status);
                dispatch(clientes_fail(errorMsg.errorMessage));
            })
    }
}